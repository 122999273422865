import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useLoginStore from "../store/useLoginStore";
import { getCookie, setCookie } from "../commons/Cookie";
import CONFIG from "../config/config";

export default function GoogleLoginButton() {
  const { setUserInfo } = useLoginStore();
  /**
   * 로그인 했는지 여부
   * 로그인 했을 경우 : true 라는 텍스트 반환
   * 로그인 안했을 경우 : null or false(로그아웃 버튼 눌렀을 경우 false로 설정)반환
   */

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get(CONFIG.googleUserInfoAPI, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      // console.log("userInfo Test:::", userInfo)
      const data = {
        tokenResponse: {
          access_token: tokenResponse.access_token,
          hd: tokenResponse.hd,
          scope: tokenResponse.scope,
          token_type: tokenResponse.token_type,
        },
        userInfo: userInfo,
        rUrl: "3000",
      };
      // console.log('data =',JSON.stringify(data))
      const rsp = await axios
        .post(CONFIG.loginAPI, data, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then(async (rsp) => {
          // console.log('rsp =',rsp.data)
          let role = "";
          if (rsp.data["role"]) {
            if (rsp.data["role"] === 0) {
              alert("login fail: 권한이 없습니다.");
              return window.location.reload();
            } else {
              // get role
              const role_url = CONFIG.userCheck;
              // console.log('role_url =',role_url)
              const adminCheckRsp = await axios
                .post(
                  role_url,
                  {
                    access_email: data.userInfo.email,
                    access_name: data.userInfo.name,
                    access_hd: data.tokenResponse.hd,
                  },
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  return res.data.role;
                });
              role = adminCheckRsp;
              // for admins
              setCookie(CONFIG.COOKIE_5, adminCheckRsp);
              setCookie(CONFIG.COOKIE_5_N, adminCheckRsp);
            }
          }

          const datas = rsp.data;
          // 스토어 변수 저장
          const value1 = datas["email"];
          const value2 = datas["displayName"];
          const value3 = datas["login"];
          const value4 = data.userInfo.hd;
          setUserInfo(value1, value2, value3);

          // let maxAge: number = 10;
          setCookie(CONFIG.COOKIE_1, value1);
          setCookie(CONFIG.COOKIE_1_N, value1);
          setCookie(CONFIG.COOKIE_2, value2);
          setCookie(CONFIG.COOKIE_2_N, value2);
          setCookie(CONFIG.COOKIE_3, value3);
          setCookie(CONFIG.COOKIE_3_N, value3);
          setCookie(CONFIG.COOKIE_4, value4);
          setCookie(CONFIG.COOKIE_4_N, value4);

          const isAuthenticated = getCookie(CONFIG.COOKIE_3);

          if (isAuthenticated) {
            return window.location.reload();
          }
        });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return <button onClick={() => googleLogin()}>Login with Google 🚀</button>;
}
