import reactCookie, { Cookies } from "react-cookie";
import CONFIG from "../config/config";

const cookies = new Cookies();
const COOKIE_OPTIONS = {
  path: "/",
  domain: ".ella.so",
};
const VALUES = [
  [CONFIG.COOKIE_1, CONFIG.COOKIE_1_N],
  [CONFIG.COOKIE_3, CONFIG.COOKIE_3_N],
  [CONFIG.COOKIE_2, CONFIG.COOKIE_2_N],
  [CONFIG.COOKIE_4, CONFIG.COOKIE_4_N],
  [CONFIG.COOKIE_5, CONFIG.COOKIE_5_N],
];
// 쿠키등록
// 1주일
let maxAge: number = 60 * 60 * 24 * 7;
const selectNames = (name: string) => {
  if (name === VALUES[0][0] || name === VALUES[0][1]) {
    return "EMAIL";
  } else if (name === VALUES[1][0] || name === VALUES[1][1]) {
    return "LOGIN";
  } else if (name === VALUES[2][0] || name === VALUES[2][1]) {
    return "USERNAME";
  } else if (name === VALUES[3][0] || name === VALUES[3][1]) {
    return "HD";
  } else if (name === VALUES[4][0] || name === VALUES[4][1]) {
    return "ROLE_CHECK";
  } else {
    return "";
  }
};
export const setCookie = (name: string, value: string) => {
  return cookies.set(name, value, {
    ...COOKIE_OPTIONS,
    sameSite: "none",
    secure: true,
    maxAge: maxAge,
  });
};

export const getCookie = (name: string) => {
  const getCookiesName = cookies.get(name);
  return getCookiesName;
};

export const removeCookie = (name: string, option?: any) => {
  return cookies.remove(name, { ...option });
};
export const removeAllCookies = async () => {
  const values = [
    CONFIG.COOKIE_1,
    CONFIG.COOKIE_1_N,
    CONFIG.COOKIE_2,
    CONFIG.COOKIE_2_N,
    CONFIG.COOKIE_3,
    CONFIG.COOKIE_3_N,
    CONFIG.COOKIE_4,
    CONFIG.COOKIE_4_N,
    CONFIG.COOKIE_5,
    CONFIG.COOKIE_5_N,
  ];
  for await (const cookieName of values) {
    cookies.remove(cookieName, COOKIE_OPTIONS);
  }

  return true;
};

export const checkAllCookies = () => {
  const hasCookieNames = [
    CONFIG.COOKIE_1,
    CONFIG.COOKIE_1_N,
    CONFIG.COOKIE_2,
    CONFIG.COOKIE_2_N,
    CONFIG.COOKIE_3,
    CONFIG.COOKIE_3_N,
    CONFIG.COOKIE_4,
    CONFIG.COOKIE_4_N,
    CONFIG.COOKIE_5,
    CONFIG.COOKIE_5_N,
  ];
  for (let i = 0; i < hasCookieNames.length; i++) {
    const target = getCookie(hasCookieNames[i]);
    // console.log('target[',i,'] =', target)
    if (!target) {
      return false;
    }
    if (i === 0 && Boolean(target) !== true) {
      // console.log('login check is :',target)
      return false;
    }
  }
  return true;
};
