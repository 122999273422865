const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : '';
const googleUserInfoAPI = process.env.REACT_APP_USERINFO_API_URL ? process.env.REACT_APP_USERINFO_API_URL:'';
const HOST_BACKEND= process.env.REACT_APP_BACK_HOST ? process.env.REACT_APP_BACK_HOST:'';

const loginAPI = process.env.REACT_APP_LOGIN_API_URL ? HOST_BACKEND+process.env.REACT_APP_LOGIN_API_URL:'';
const userCheck = process.env.REACT_APP_LOGIN_HS_USER_CHECK_URL ? HOST_BACKEND+process.env.REACT_APP_LOGIN_HS_USER_CHECK_URL:'';

const COOKIE_1 = process.env.REACT_APP_COOKIE_LOGIN_NAME1?process.env.REACT_APP_COOKIE_LOGIN_NAME1:'';
const COOKIE_2 = process.env.REACT_APP_COOKIE_LOGIN_NAME2?process.env.REACT_APP_COOKIE_LOGIN_NAME2:'';
const COOKIE_3 = process.env.REACT_APP_COOKIE_LOGIN_NAME3?process.env.REACT_APP_COOKIE_LOGIN_NAME3:'';
const COOKIE_4 = process.env.REACT_APP_COOKIE_LOGIN_NAME4?process.env.REACT_APP_COOKIE_LOGIN_NAME4:'';
const COOKIE_5 = process.env.REACT_APP_COOKIE_LOGIN_NAME5?process.env.REACT_APP_COOKIE_LOGIN_NAME5:'';

const COOKIE_1_N = process.env.REACT_APP_COOKIE_LOGIN_NAME1_N?process.env.REACT_APP_COOKIE_LOGIN_NAME1_N:'';
const COOKIE_2_N = process.env.REACT_APP_COOKIE_LOGIN_NAME2_N?process.env.REACT_APP_COOKIE_LOGIN_NAME2_N:'';
const COOKIE_3_N = process.env.REACT_APP_COOKIE_LOGIN_NAME3_N?process.env.REACT_APP_COOKIE_LOGIN_NAME3_N:'';
const COOKIE_4_N = process.env.REACT_APP_COOKIE_LOGIN_NAME4_N?process.env.REACT_APP_COOKIE_LOGIN_NAME4_N:'';
const COOKIE_5_N = process.env.REACT_APP_COOKIE_LOGIN_NAME5_N?process.env.REACT_APP_COOKIE_LOGIN_NAME5_N:'';

const CONFIG = {
  googleClientId,
  googleUserInfoAPI,
  loginAPI,
  userCheck,
  COOKIE_1,
  COOKIE_2,
  COOKIE_3,
  COOKIE_4,
  COOKIE_5,
  COOKIE_1_N,
  COOKIE_2_N,
  COOKIE_3_N,
  COOKIE_4_N,
  COOKIE_5_N
}

export default CONFIG;